/* Navbar */
.title-text {
  font-family: Futura, Helvetica, sans-serif;
  color: black;
  font-weight: bold;
  -webkit-text-stroke: 1px black;
  paint-order: stroke fill;
  letter-spacing: -1px;
  font-size: 3vh;
  text-align: center;
}

.title-container {
  height: 6vh;
  align-items: center;
  justify-content: center;
}

.nav-links {
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: inherit;
  text-align: center;
  font-family: Helvetica;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -1.2px;
  text-decoration: none; /* Remove underline */
}

.nav-container {
  color: black;
  height: 2rem;
  flex: 1 0 auto;
  border-left: black solid 1px; 
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-container:hover {
  background-color: #000;
  color: white;
}

.nav-container:active {
  background-color: #000;
  color: white;
}

.nav-container:first-child {
  border-left: none;
}

.navbar-links {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #000; /* Add a border */
  border-bottom: 1px solid #000; /* Add a border */
}

.page-divider {
  display: flex;
  height: 2rem;
  padding: 0.5rem 0 0.5rem 0;
  border-bottom: 1px solid #000;
  justify-content: center;
  align-items: center;
  font-family: Helvetica;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -1.2px;
}

/* footer */

.footer {
  color: black;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border-top: solid black 1px;
  justify-content: center;
}

.social-icons {
  /* margin-top: 0.5em; */
  /* position: right; */
}

.social-icons a {
  color: #000;
  margin: 2rem;
  font-size: 1rem;
}

.social-icons a:hover {
  color: #EF1E1E;
}


/* gallery */

.gallery-card {
  display: block;
  background-color: white;
  align-items: center;
  width: 25%;
  padding: 0.5rem;
}

.gallery-img {
  width: 100%;
}

.gallery-label {
  display: flex;
  justify-content: space-between;
}

.section {
  display: block;
}

.gallery-section {
  display: block;
  font-family: Helvetica;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.05625rem;
  width: 100%;
}

.gallery-card-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3rem;
}

/* events */

/* resources */

.resource-card-wrapper {
  height: 25%;
  display: grid;
  grid-template-columns: 40% 60%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  margin-top: 3rem;
}

.resource-card-wrapper > img {
  height: 25vw;
  width: stretch;
  object-fit: cover;
  object-position: 20% 20%;
}

.resource-card-text-wrapper {
  text-align: center;
  align-content: center;
  padding: 0% 10% 0% 10%;
}

.resource-card-text-wrapper > * {
  margin: 0.5rem;
}

/* may want to move these up top after merging all pages */
h1 {
  font-size: 3rem;
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.1875rem;;
}

p {
  font-size: 1.25rem;
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.075rem;
}


/* About Us Page */
.about-page {
  display: flex; /* Create a horizontal layout */
  align-items: stretch; /* Stretch both sides to fill the height equally */
  width: 100%; /* Ensure the container spans the full width of the page */
}

.about-image {
  flex: 1; /* Make the image take up the entire left side */
}

.about-image img {
  width: 100%; /* Ensure the image spans the full width of the container */
  height: 100%; /* Stretch the image to fill the container */
  object-fit: cover; /* Ensure the image scales without distortion */
}

.about-description {
  flex: 1; /* Make the text take up the entire right side */
  display: flex; /* Enable flexbox layout */
  flex-direction: column; /* Stack items vertically */
  justify-content: center; /* Center text vertically within the section */
  padding: 20px; /* Add padding inside the text section */
}

/* .about-card-wrapper {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
} */

.about-card-wrapper h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

/* calendar css */

.day-card {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  font-size: 2.5rem;
  font-weight: bold;
  padding: 5px;
  height: 50px;
  border-radius: 50%;
  background: none;
  color: inherit;
  border: none;
  cursor: pointer;
  outline: inherit;
  height: 5vw;
  width: 5vw;
}

.day-card:hover {
  text-decoration: underline;
}

.days-wrapper {
  display: grid;
  grid-template-columns: repeat(7, 0.5fr);
  grid-template-rows: repeat(5, 0.5fr);
  list-style-type: none;
  margin: none;
  padding: 0;
  height: 80%;
}

.full-calendar {
  display: flex;
  justify-content: space-between;
}

.calendar-image {
  width: 50%;
}

.calendar-image img {
  width: 100%;
}

.month-header {
  font-size: 8vh;
  align-items: center;
  margin: 5px;
  letter-spacing: -1.2px;
  text-align: center;
}

.calendar-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6vw 6vw 0vw 6vw;
  justify-content: space-between;
}

.calendar-right-panel {
  width: 50%;
}

ul {
  width: 100%;
}

.weekdays-wrapper {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  list-style-type: none;
  margin: none;
  padding: 0;
}

.weekday-card {
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
  list-style: none;
  font-weight: bold;
  font-size: 1.5vw;
  letter-spacing: -1.2px;
}

.event-description-wrapper {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  height: 20%;
  align-items: center;

  p {
    font-size: 1rem;

  }
}


.all-dates-wrapper {
  height: 70%;
}

.page {
  min-height: 100vh;
}

/* Mobile styles (default) */
@media (max-width:768px)  {
  .gallery-card {
    width: 100%;
    /* margin:20px; */
  }

  .gallery-card-wrapper {
    width: 100%;
    justify-content: space-between;
  }
  .gallery-img {
    width: 100%;
  }
  .title-text {
    font-size: 2vh;

  }
  .title-container {
    height: 4vh;
  }
  .navbar-links {
    a {
      font-size: 0.8rem;
    }

  }

  .nav-container {
    height: 1.5rem;
  }

  .gallery-section {
    margin-top: 5vh;
  }

  .full-calendar {
    max-width: 768px;
    flex-wrap: wrap;
  }

  .calendar-image {
    width: 100%;
    display: none;
  }

  .calendar-right-panel {
    width: 100%;
  }


  /* div {
      max-width: 768px;
      margin: 0 auto;
  }

  .header, .content, .footer {
      padding: 20px;
  } */
}
/* @media (max-width: 768px) {
  .header, .content, .footer {
    padding: 10px;
    text-align: center;
    }
    div {
        max-width: 760px;
        margin: 0 auto;
    }
} */



/* Tablet styles */
@media (min-width: 768px) and (max-width:1024px)  {
  .gallery-card {
    width: 45%;
  }
  /* div {
      max-width: 768px;
      margin: 0 auto;
  }

  .header, .content, .footer {
      padding: 20px;
  } */
}

/* Laptop styles */
@media (min-width: 1024px) {
  /* div {
      max-width: 1024px;
      margin: 0 auto;
  }

  .header, .content, .footer {
      padding: 30px;
  } */
}

/* Desktop styles */
/* @media (min-width: 1200px) {
  div {
      margin: 0 auto;
  } */

  /* .header, .content, .footer {
      padding: 40px;
  } */
/* } */

